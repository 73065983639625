import React from "react";
import { Footer, Header, JobOpeningBlock} from "../../templates";
import { Helmet as ReactHelmet } from "react-helmet";
import { useLang } from "../../../helpers/context/LanguageProvider";

const VacancyPage = () => {
  const {language, translate} = useLang();

  return (
      <>
        <ReactHelmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="KomandaPL" content={translate.mainPage.banner.title[language.name]} />
          <meta name="description" content={translate.seo.description[language.name]} />
          <meta name="keywords" content={translate.seo.keywords[language.name]} />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <meta name="robots" content="index,follow" />
          <title>Komanda praca</title>
        </ReactHelmet>
        <div>
          <section className="header--section">
            <Header />
          </section>
          <section className="job-opening--section">
            <JobOpeningBlock />
          </section>
          <section className="footer--section">
            <Footer />
          </section>
        </div>
      </>

    )
}

export default VacancyPage;